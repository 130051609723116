import * as React from "react"
import LogoImg from "../images/logos/main.svg"

const headerStyles = {
    background: "#1b1e23",
    color: "white",
    width: "100%",
    display: "flex",
}

const aStyles = {
    color: "#eae6e5",
    fontSize: "3vw",
    fontFamily: "sans-serif",
    textDecoration: "none",
    display: "inline",
}

const divStyles = {
    display: "inline-block",
    width: "15%",
    textAlign: "center",
    verticalAlign: "bottom",
    flex: "1 1 auto",
    padding: "2px",
}

const imgStyles = {
    width: "100%"
}

// TODO - FLEXBOX!
function HeaderBar(prop) {
    return (<header style={headerStyles}>
        <div style={divStyles}>
            <a href="/" style={aStyles}>
                <img style={imgStyles} src={LogoImg} alt="Main Logo"/>
            </a>
        </div>
        <div style={divStyles}>
            <a href="/resume" style={aStyles}>Resume</a>
        </div>
        <div style={divStyles}>
            <a href="/current" style={aStyles}>Current Projects</a>
        </div>
        <div style={divStyles}>
            <a href="/projects" style={aStyles}>All Projects</a>
        </div>
        <div style={divStyles}>
            <a href="/skills" style={aStyles}>Skills</a>
        </div>
    </header>)
}

export default HeaderBar