import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import ProjectSeries from "../components/ProjectSeries"
import GithubLink from "../components/GithubLink"

const TaAssignmentPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>TA Tools - Sample Assignment</h1>
            {GithubLink("https://github.com/Gautreaux/PA5-Topologial-Sort")}
            {ProjectSeries({
                name: "TA Tools",
                prevname: "Autoformatter",
                prevlink: "/autoformatter",
                nextname: "Gradescope Grader",
                nextlink: "/gradescope",
            })}
            <p>Because:</p>
            <ul>
                <li>This is a great example of my writing Google Test code</li>
                <li>We gave this information to students anyway</li>
                <li>This assignment is no longer being actively used</li>
                <li>It took seconds to clone and upload</li>
                <li>I really like this assignment (probably cause I wrote it)</li>
                <li>I conceptually could see someone asking in the future</li>
            </ul>
            <p>I think I should be ok to share this and no-body will get mad.</p>
            <p>The GitHub link above takes you to a repo containing the starter code and test cases for a sample assignment given when I was a TA. This assignment tests student's on implementing a topological ordering on a directed graph in C++. The graph is (recommended to be) done via `std::unordered_map` and `std::vector`. The test cases use some given graphs and then also randomly generate graphs with certain properties (disjoint, cycles) to test the edge cases of the student's implementation. Of course, the test cases are written to accept any ordering produced by the students and can validate that this is a valid topological ordering. The test cases are written in Google Test since this assignment ran on Mimir.</p>
            {FooterBar()}
        </div>
    )
}

export default TaAssignmentPage