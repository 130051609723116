import * as React from "react"

import GithubImg from "../images/logos/github.svg"
import Grabcad from "../images/logos/grabcad.svg"
import LinkedinImg from "../images/logos/linkedin.svg"

const logos = [
    {
        link: "https://www.linkedin.com/in/spencer-gautreaux/",
        src: LinkedinImg,
        alt: "LinkedIn Logo",
    },
    {
        link: "https://github.com/Gautreaux",
        src: GithubImg,
        alt: "GitHub Logo",
    },
    {
        link: "https://grabcad.com/spencer.gautreaux-2",
        src: Grabcad,
        alt: "GrabCad Logo",
    }
]

const footerStyles = {
    background: "#1b1e23",
    color: "white",
    width: "100%",
    // position: "fixed",
    // bottom: "0",
}

const footerTEStyles = {
    display: "inline-block",
    padding: "0px",
    height: "75px",
    width: "75px",
    margin: "5px",
    textAlign: "center",
}

const logoRowStyles = {
    borderCollapse: "collapse",
    display: "block",
    maxWidth: "263px",
    margin: "auto",
    marginTop: "5px",
}

const footerLogoStyles = {
    display: "flex",
    width: "75%",
    height: "75%",
    position: "relative",
    top: "12.5%",
    left: "12.5%",
    borderRadius: "50%",
}

const footerDivStyles = {
    display: "flex",
    position: "relative",
    backgroundColor: "#1038A5",
    height: "100%",
    width: "100%",
    borderRadius: "50%",
}

function FooterLogo(prop) {
    return (
        <div style={footerTEStyles}>
            <a href={prop.link}>
                <div style={footerDivStyles}>
                    <img style={footerLogoStyles} src={prop.src} alt={prop.alt}></img>
                </div>
            </a>
        </div>
    );
}

// TODO - make footer float to the bottom appropriately
//  i have a feeling this might be a major rework...
function FooterBar(prop) {
    return (<footer style={footerStyles}>
        <div style={logoRowStyles}>
        {logos.map(e => (
            FooterLogo(e)
        ))}
        </div>
        <div>
            Questions? Comments? Contact: <address><a href="mailto:geauxforgautreaux@gmail.com">Spencer Gautreaux</a></address>
        </div>
        <div>
            Copyright 2022 Spencer Gautreaux. All Rights Reserved.
        </div>
    </footer>);
}

export default FooterBar