import * as React from "react"

const divStyles = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "lightgray",
    padding: "2px",
    margin: "5px 0 5px 0",
}

const aStyles = {
    display: "inline-block",
    flex: "1 1 0",
    textAlign: "left",
}
const a2Styles = {
    display: "inline-block",
    flex: "1 1 0",
    textAlign: "right",
}

const pStyles = {
    display: "inline-block",
    flex: "1 1 0",
    textAlign: "center",
    margin: "0",
}

function ProjectSeries(prop){
    return (
        <div style={divStyles}>
            <a style={aStyles} href={prop.prevlink}>Previous: {prop.prevname}</a>
            <p style={pStyles}>This project is part of a series on {prop.name}</p>
            <a style={a2Styles} href={prop.nextlink}>Next: {prop.nextname}</a>
        </div>
    )
}

export default ProjectSeries